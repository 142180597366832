import React from 'react';
import Img from 'gatsby-image';
import { RichText } from 'prismic-reactjs';
import { Link } from 'gatsby';

import ContentSection from 'components/ContentSection';
import Button from 'components/Button';

import css from './blog.module.scss';
import shared from 'styles/shared.module.scss';

const Blog = ({ data: { prismic } }) => {
  let posts = prismic.allBlog_posts.edges;

  let firstPost = null;
  let sections = [];
  if (posts.length > 0) {
    posts = posts.filter(post => post.node.listable !== false);
    firstPost = posts[0].node;
    const sectionList = posts.length > 1 ? posts.slice(1) : [];
    sections = sectionList.map(item => {
      const singlePost = item.node;
      return {
        image: singlePost.imageSharp
          ? singlePost.imageSharp.childImageSharp.fluid
          : null,
        date: singlePost.date,
        title: singlePost.title,
        description: singlePost.abstract,
        clickableUrl: `/blog/${singlePost._meta.uid}`,
      };
    });
  }

  return (
    <>
      <section className={`${css.highlight} ${shared.maxwidth}`}>
        <div className={css.highlightBkg} />
        <div className={css.content}>
          <div className={css.left}>
            <h4 className={shared.sectionType}>{firstPost.date}</h4>
            <h2 className={shared.sectionTitle}>
              {RichText.asText(firstPost.title)}
            </h2>
            <p className={css.description}>
              {RichText.asText(firstPost.abstract)}
            </p>
            <Link to={`/blog/${firstPost._meta.uid}`}>
              <Button filled>Read more</Button>
            </Link>
          </div>
          <div className={css.image}>
            {firstPost.imageSharp ? (
              <Img
                fluid={firstPost.imageSharp.childImageSharp.fluid}
                className={css.valuesImage}
                alt="Blog post"
              />
            ) : null}
          </div>
        </div>
      </section>
      <section className={`${css.list} ${shared.maxwidth}`}>
        <ContentSection
          type={[]}
          title={[]}
          alignment="left"
          sections={sections}
          keepLeft
        />
      </section>
    </>
  );
};

export default Blog;

export const pageQuery = graphql`
  query Posts {
    prismic {
      allBlog_posts(sortBy: date_DESC) {
        edges {
          node {
            _meta {
              uid
            }
            image
            imageSharp {
              childImageSharp {
                fluid(quality: 90, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            title
            abstract
            content {
              quote
              text
            }
            date
            author
            listable
          }
        }
      }
    }
  }
`;
